import React from 'react';
import { Link } from 'react-router-dom';
import { blogs } from './BlogData';
import './BlogList.css'

const BlogList = () => {
  return (
    <div className='blogListContainer'>
      <h1>个人成长系列：</h1>
      <ul className='no-bullets'>
        {blogs.map(blog => (
          <div>
            <li key={blog.id}>
              <Link className='styled-link' to={`/blog/${blog.id}`}>{blog.title}</Link>
            </li>
            
          </div>
          
        ))}
      </ul>
    </div>
  );
};

export default BlogList;
