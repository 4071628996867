export const selfDevelopment4 = {
    id: 4,
    title: "个人成长第四弹：锻炼身体，拥有健康的体魄",
    content: 
    <div>
      <p>拥有健康的身体，这是重中之重。一个病怏怏的人，整日都与病魔作斗争，根本无暇去关注其他方面了，个人成长无从谈起。因此，我们必须注重保养好身体，才有心思去发展其他方面。</p>
      
      <h2>1.规律运动</h2>
      <p> 一直躺着不动，身体很容易出现各种问题，而过度锻炼，对身体也是有害的，运动员一般都不长寿。所以规律、适量的运动是最佳选择。
  个人认为最佳的频率是一周运动3次左右，隔一两天运动一次，既能让身体得到充分的休息，也能得到充分锻炼。
  如果我隔很久不运动，我会感觉身体有点虚，而经过一场酣畅淋漓的运动之后，会感觉身体活力增加了，更有精神。</p>

      <h2>2.注意饮食</h2>
      <p>吃健康的、有营养的食物，摄入足够的营养去滋养身体，让身体维持在健康是水平。如果长期不注重饮食均衡，缺乏某种营养，身体就会出问题。
      想要健康长寿，建议清淡饮食，少辣少油少盐，不要吃太多刺激性的食物。</p>

      <h2>3.节欲</h2>
      <p>放纵欲望会让身体阳气快速流失，让身体变得虚弱。应该少欲知足，学会保存精力。让身体维持在精力充沛的状态，这样才能百邪不侵，不容易生病，反正如果抵抗力低下，很容易生病，稍有风吹草动身体就受不了了。</p>

      <h2>4.多喝水</h2>
      <p>这点看起来琐细，但是反复强调也不为过。有些人就是不容易感到渴，因此不爱喝水，身体长期处于缺水的状态，这样很容易造成结石，等到结石变大不得不手术，才后悔莫及。
      喝水皮肤也有好处，滋润皮肤，帮助身体排出废物。</p>
    </div>
  }

  

  
  
 
  
  
  
  
 
  



