import React from "react"
import './AppLink.css'
import { NavLink } from "react-router-dom";

const AppLink = ({desc, link}) => {
    return (
        <div className="description-container">
            <p className="description-text">
                <strong>{desc}</strong>
                <a 
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="description-link"
                >
                    下载链接
                </a>
            </p>

            <p className="description-text">
                <strong>更多念佛計數器：</strong>
                <NavLink to="/solo-product" className="description-link">點擊這裏</NavLink>
            </p>
        </div>
    );
}

export default AppLink