export const selfDevelopment2 = {
    id: 2,
    title: "个人成长第二弹：学习一门挣钱的技能",
    content: 
    <div>
      <p>技多不压身，会的东西多了，挣钱的空间自然也就大。</p>
      <h2>1.学习编程</h2>
      <p>编程可以说是当下最火爆的技能了，程序员的平均工资比传统产业的从业者高出一大截，其他行业领导层的职位才能月入过万，而程序员基本起薪就有一万了，工资上有很大的优势，可以帮助你快速积累财富。
现在编程行业竞争大，没有前几年那么好做了。但整个经济下行的情况下，其他行业也好不到哪去，工资还低，既然如此，那还不如选挣钱多的。
编程技能还有个好处，它是全球通用的，无论哪个国家都需要编程人才，而且技术栈大同小异，你可以靠编程挣其他国家的钱，汇率差能让你比同龄人多赚很多钱。</p>

      <h2>2.学习AI</h2>
      <p>近两年要说什么行业最火爆，莫过于AI行业了，openAI 出的chatgpt就像重磅炸弹一样，在整个世界引起轩然大波，投资者热衷于投资AI相关概念产品，大量热钱流入，英伟达的股票都涨了十倍了。
      可以说，AI行业是目前离钱最近的行业，如果你学习了AI技能，会有更多的就业机会。而且有了AI加持，你大可以创造一人企业，让AI帮助打工。目前AI写作、绘画、生成视频都可以做到了，相当于拥有全能小助手。</p>

      <h2>3.学习传统手艺，如中医、玄学</h2>
      <p>尽管科技日新月异，但人们身心健康依然是很棘手的问题，有些人反其道而行，不去拥抱新技术，反而转身去学习传统技能。
人是肉体凡胎，难免会生病，现代人大多都处于亚健康状态，学会中医，去帮人们调理身体，你会拥有更加稳定的收入。这个行当更加久远、稳定，波动不大。而AI行业机会多，但是也充满变数。
玄学也是现在很火的领域，帮人看事、算命，解答困惑，趋吉避凶。玄学方面做得好，收入就是天文数字了，来找你的非富即贵，完全不差钱。不过从事这个行当要特别注意因果，强行介入别人因果，对自己是不利。不可泄漏天机。</p>
    </div>
  }

  





