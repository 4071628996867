import React from 'react';
import './DiZangIntro.css';

const DiZangIntro = () => {
  return (
    <div className="dizang-intro">
      <h1 className="intro-title">地藏菩薩簡介</h1>

      <section className="intro-section">
        <h2 className="section-title">1. 地藏菩薩聖號的意義</h2>
        <p className="section-text">
          地藏菩薩的聖號全稱為「南無地藏菩薩摩訶薩」，其中「南無」是皈依和恭敬的意思，表示願意依靠和敬仰菩薩。「地藏」象徵著大地的寬厚與包容，寓意菩薩如同大地般能承載萬物、滋養眾生。「摩訶薩」則是「大菩薩」的意思，代表地藏菩薩是發願廣大、具備慈悲智慧的偉大菩薩。
        </p>
        <p className="section-text">
          地藏菩薩以無量的慈悲，願意救度一切眾生，特別是在困苦和墮落的眾生中，地藏菩薩發願不證菩提，誓不成佛，表現出他無盡的慈悲與毅力。
        </p>
      </section>

      <section className="intro-section">
        <h2 className="section-title">2. 《地藏菩薩本願經》</h2>
        <p className="section-text">
          《地藏菩薩本願經》是佛教中的重要經典之一，記載了地藏菩薩在久遠劫以前所發的廣大願力，主要的誓願是「地獄不空，誓不成佛」，即願救拔一切眾生，尤其是那些因造作惡業而墮入地獄的苦難眾生。
        </p>
        <p className="section-text">
          經文分為三卷，內容涵蓋地藏菩薩如何廣大地救度生死輪迴中的眾生，尤其是眾生在五濁惡世中的苦難。經中也詳細介紹了供養地藏菩薩的功德利益，並勸導人們行善積德，孝順父母，敬重三寶，修持善法。
        </p>
      </section>

      <section className="intro-section">
        <h2 className="section-title">3. 念地藏菩薩的利益</h2>
        <p className="section-text">
          念誦「南無地藏菩薩摩訶薩」的聖號能帶來以下利益：
        </p>
        <ul className="benefit-list">
          <li>消除業障：地藏菩薩以慈悲心願救拔苦難眾生，念誦其聖號能幫助消除過去所造的惡業，減輕業報，解脫困境。</li>
          <li>增長福報：通過念誦地藏菩薩名號、禮拜供養，可以積累廣大的福德資糧，護持眾生修行，帶來生活中的順利和安穩。</li>
          <li>護佑家宅平安：地藏菩薩本身與孝順父母、守護家族有關，誠心念誦可保佑家宅安寧，消除災難，增進親屬的和睦與幸福。</li>
          <li>冥陽兩利：地藏菩薩發願救拔冥界眾生，念誦其名號不僅能利益現世，還可以回向給已故的親人和其他過往眾生，幫助他們往生善道。</li>
          <li>解除恐懼：念地藏菩薩名號可解除內心的恐懼與不安，增強信心和勇氣，面對生活中的困難和挑戰。</li>
        </ul>
        <p className="section-text">
          地藏菩薩以大願、大悲、大慈心護持眾生，誠心念誦聖號並依教奉行，可以得到無量無邊的功德與福報，並最終引導眾生脫離苦海，得證菩提。
        </p>
      </section>
    </div>
  );
};

export default DiZangIntro;
