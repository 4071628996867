import LanguageDetector from "../language-detector/LanguageDetector";
import './Home.css'; // 导入样式文件

function Home() {
    return (
    <div className="home">
        <h2>Hello</h2>
        <p>I am Poet.</p>
        <p style={{fontWeight: 'bold'}}>A solo developer.</p>
        <p>I like to develop some useful online tool.</p>
        <p>我也喜欢写一些博客文章</p>
        <p>Nice to meet you!</p>
    </div>
    );
}

export default Home;