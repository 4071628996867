import './Support-me.css'
import React from 'react';


const SupportMe = () => {
    return (
      <section className='support-me'>
        <h1>paypal:</h1>
      
      <div className='donate'>
        <a
            href="https://paypal.me/PoetWu?country.x=C2&locale.x=zh_XC"
            target="_blank" 
            rel="noopener noreferrer"
            className="donation-link"
            >
        
       
        click here to support (點此PayPal支持我)
      </a>
      <p>Or search account(或搜索PayPal賬號): sysuwws@gmail.com</p>
      </div>

      <h1>Buy Me a Coffee: </h1>
      <div className='donate'>
        
        <a
              href="https://buymeacoffee.com/PoetWu"
              target="_blank" 
              rel="noopener noreferrer"
              className="donation-link"
              >
                Buy me a Coffee
        </a>
    
        <img 
        src={`${process.env.PUBLIC_URL}/images/bmc_qr.png`} 
        alt="bmc donate" 
        width="300" 
        height="300" 
        // className='picture'
        />
    
      </div>
      

      <h1>Wechat/微信：</h1>
      <div className='donate'>
        <img 
        src={`${process.env.PUBLIC_URL}/images/wechat-donate.jpg`} 
        alt="wechat donate" 
        width="300" 
        height="300" 
        />
      </div>
      
      <h1>Crypto/虛擬貨幣：</h1>
      <div className='donate'>
        <p>Network/網絡：BNB Smart Chain(BEP20)</p>
        <p>Deposit Address/地址：</p>
        <p className='crypto-address'>0xb1723b07f83f7da264d1a4ffd96e615686c85dd5</p>
  
      </div>
      
      </section>
    );
  };
  
export default SupportMe;