import { selfDevelopment1 } from "./data/self-develop/SelfDevelopment1"
import { selfDevelopment2 } from "./data/self-develop/SelfDevelopment2";
import { selfDevelopment3 } from "./data/self-develop/SelfDevelopment3";
import { selfDevelopment4 } from "./data/self-develop/SelfDevelopment4";
import { selfDevelopment5 } from "./data/self-develop/SelfDevelopment5";
import { selfDevelopment6 } from "./data/self-develop/SelfDevelopment6";
import { selfDevelopment7 } from "./data/self-develop/SelfDevelopment7";
import { selfDevelopment8 } from "./data/self-develop/SelfDevelopment8";
import { selfDevelopment9 } from "./data/self-develop/SelfDevelopment9";
import { selfDevelopment10 } from "./data/self-develop/SelfDevelopment10";

export const blogs = [
    selfDevelopment1,
    selfDevelopment2,
    selfDevelopment3,
    selfDevelopment4,
    selfDevelopment5,
    selfDevelopment6,
    selfDevelopment7,
    selfDevelopment8,
    selfDevelopment9,
    selfDevelopment10
  ];
  