import React, { useState } from 'react';
import {franc} from 'franc';
import languageNameEn from './LanguageNames-en';
import languageNamesCn  from './LanguageNames-cn';
import './InputBox.css';

const InputBox = () => {
  const [inputText, setInputText] = useState('');
  const [detectedLanguage, setDetectedLanguage] = useState('');
  const [displayLanguage, setDisplayLanguage] = useState('en'); // Default display language is English

  const detectLanguage = () => {
    const languageCode = franc(inputText);
    var languageName;
    if(displayLanguage === 'en') {
      console.log(1)
      languageName = languageNameEn[languageCode];
    } else {
      console.log(2)
      languageName = languageNamesCn[languageCode];
    }

    console.log("displayLanguage:" + displayLanguage);
    console.log("languageCode:" + languageCode);
    console.log("languageName:" + languageName);
    setDetectedLanguage(languageName);
  };

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const changeLanguage = (lng) => {
    setDisplayLanguage(lng);
    setDetectedLanguage('');
  };

  return (
    <div className="container">
      <textarea value={inputText} onChange={handleInputChange} rows={4} cols={50} />
      <button onClick={detectLanguage}>{displayLanguage === 'en'? 'language detect' : '检测语言'}</button>
      <p>{displayLanguage === 'en'? 'detected language:' : '检测到语言：'} {detectedLanguage}</p>
      
      <button
        onClick={() => changeLanguage('en')}
        className={displayLanguage === 'en' ? 'selected' : ''}
      >
        English
      </button>
      <button
        onClick={() => changeLanguage('zh')}
        className={displayLanguage === 'zh' ? 'selected' : ''}
      >
        中文
      </button>
    </div>
  );
};

  export default InputBox;