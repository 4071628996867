export const selfDevelopment1 = {
    id: 1,
    title: "个人成长第一弹：培养正确的金钱观",
    content: 
    <div>
        <h2>1.学会储蓄</h2>
        <p>阅读《穷爸爸，富爸爸》《小狗钱钱》等培养财商的书籍，不要当月光族，更不要去提前消费。当挣到钱之后，应该把30%-50%的钱储蓄起来，这样随着时间过去，你的积蓄也会逐渐增多。
当你有了积蓄之后，你在财务上的安全性就会得到保证。当需要有大额的购物、医疗支出、旅行支出时，你可以用自己的积蓄去支付，不必要去负债，人生会轻松很多。
不像月光族或者依赖信用卡生活的人，会储蓄的人随时可以跟工作说拜拜，在职场遇到不如意的事也会有更多选择空间，随时可以进入gap year，或者跳槽换工作。而经济上负债、没有储蓄的人，则很依赖一份工作，一旦没有收入，他们就陷入经济危机了。
所以，学会储蓄真的很重要。</p>

        <h2>2.学会理财</h2>
        <p>有了积蓄之后，应该让钱生钱，可以把钱投入股票、基金、房产、加密货币等领域，随着这些资产增值，你的积蓄也会随着增多，你只要躺着数钞票就好了。这个时候你不再只依赖工资来存钱，钱本身会自我增殖，而且增殖的数量会越来越多，钱越多越容易钱生钱。
        上述的培养财商的入门书籍，也会讲到理财的重要性，我们一定要重视起来。多找基本相关的经济学的书籍，培养理财的思维。</p>

        <h2>3.创造被动收入</h2>
        <p>有两种类型的收入，一种是主动型的收入，如工资，一种是被动型的收入，如股票、房租、专利等。
主动型的收入需要你投入很多时间、精力，需要你人肉去做事情，手停口停。你一旦不工作，就没有收入了，纯纯地靠时间换取金钱。
被动性的收入不需要你亲自去做每一件事i，你投入资金，钱自己就会生钱了。有一些看起来是主动型的收入，其实也有很大比例的被动型特点，比如写书、经营youtube频道，你需要花大量的时间创造内容，但是带来的收入有长尾效应，你即使什么也不做，后面也会有版税、广告费。</p>
    </div>
    

  }