import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [isHidden, setIsHidden] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const handleScroll = () => {
    const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (currentScrollTop > lastScrollTop) {
      // Scroll down, hide the navbar
      setIsHidden(true);
    } else {
      // Scroll up, show the navbar
      setIsHidden(false);
    }
    setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop); // For Mobile or negative scrolling
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);

  return (
    <nav className={`navbar ${isHidden ? 'hidden' : ''}`}>
      <div className="navbar-container">
        <NavLink to="/" className="navbar-logo">Freedom & Happiness</NavLink>
        <ul className="navbar-menu">
          <li className="navbar-item">
            <NavLink 
              to="/" 
              className={({ isActive }) => (isActive ? "navbar-link active" : "navbar-link")}
              end
            >
              Home
            </NavLink>
          </li>
          <li className="navbar-item">
            <NavLink 
              to="/blog" 
              className={({ isActive }) => (isActive ? "navbar-link active" : "navbar-link")}
            >
              Blog
            </NavLink>
          </li>
          <li className="navbar-item">
            <NavLink 
              to="/solo-product" 
              className={({ isActive }) => (isActive ? "navbar-link active" : "navbar-link")}
            >
              Solo Product
            </NavLink>
          </li>
          <li className="navbar-item">
            <NavLink 
              to="/support-me" 
              className={({ isActive }) => (isActive ? "navbar-link active" : "navbar-link")}
            >
              Support me
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
