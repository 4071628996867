import InputBox from "./InputBox";
import './LanguageDetector.css'

function LanguageDetector() {
    return (
        <div className="languageDetector">
            <InputBox />
        </div>
        
    );
}

export default LanguageDetector