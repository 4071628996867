import React from 'react';
import BlogItem from './BlogItem';

const BlogDetail = () => {
  return (
    <div>
      <BlogItem />
    </div>
  );
};

export default BlogDetail;
