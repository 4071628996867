import React, { useState, useEffect, useRef } from 'react';
import './DiZangPuSa.css';
import DiZangIntro from './DiZangIntro';
import AppLink from '../compose/AppLink';

const DiZangPuSa = () => {
  const setFavicon = (iconURL) => {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/png'; // 或者 'image/x-icon' 根據你的 favicon 格式
    link.rel = 'icon';
    link.href = iconURL;
    document.getElementsByTagName('head')[0].appendChild(link);
  };
  
  // 使用例子
  setFavicon(`${process.env.PUBLIC_URL}/images/Poet.png`);

  

  const [count, setCount] = useState(() => {
    const savedCount = localStorage.getItem('dizangCount');
    return savedCount ? parseInt(savedCount, 10) : 0;
  });

  const [isCounting, setIsCounting] = useState(false); // 記錄是否自動計數
  const [mode, setMode] = useState('manual');
  const [intervalTime, setIntervalTime] = useState(1);
  const [showText, setShowText] = useState(false); // 控制顯示文字
  const [instruction, setInstruction] = useState(''); // 根據模式顯示說明文字

  let timerRef = useRef(null); // 使用 ref 儲存 timer 引用

  // 更新 localStorage
  useEffect(() => {
    localStorage.setItem('dizangCount', count);
  }, [count]);

  // 處理鍵盤事件
  useEffect(() => {
    const handleKeyDown = (event) => {
      event.preventDefault(); // 防止頁面滾動
      if (mode === 'manual') {
        if (event.code === 'Space' || event.code === 'ArrowUp' || event.code === 'KeyJ') {
          setCount(prevCount => prevCount + 1);
        } else if (event.code === 'ArrowDown' || event.code === 'KeyF') {
          setCount(prevCount => Math.max(prevCount - 1, 0));
        }
      } else if (mode === 'automatic') {
        if (event.code === 'Space') {
          toggleCounting();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [mode, isCounting]);

  // 處理自動計數
  useEffect(() => {
    if (mode === 'automatic' && isCounting) {
      timerRef.current = setInterval(() => {
        setCount(prevCount => prevCount + 1);
      }, intervalTime * 1000);
    }

    // 清除計時器以避免重複啟動
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current); // 確保清理計時器
        timerRef.current = null; // 清空引用
      }
    };
  }, [isCounting, intervalTime, mode]);

  // 更新說明文字
  useEffect(() => {
    if (mode === 'manual') {
      setInstruction('點擊上面按鈕，或使用空格鍵、下箭頭鍵或 J 鍵增加計數；上箭頭鍵或 F 鍵減少計數。');
    } else {
      setInstruction('亦可按空格鍵開始/停止');
    }
  }, [mode]);


  // 當計數發生變化時顯示文字，並持續 500ms
  useEffect(() => {

    if (count > 0) {
      setShowText(true);
      const textTimer = setTimeout(() => setShowText(false), 500);
      return () => clearTimeout(textTimer);
    }
  }, [count]);

  const handleManualCount = () => {
    setCount(prevCount => prevCount + 1);
  };

  const handleModeChange = (newMode) => {
    setMode(newMode);
    if (newMode === 'manual') {
      setIsCounting(false); // 切換到手動模式時停止自動計數
    }
  };

  const toggleCounting = () => {
    if (isCounting) {
      clearInterval(timerRef.current); // 停止自動計數，清除計時器
      timerRef.current = null; // 重置 timerRef 引用
    }
    setIsCounting(prev => !prev); // 切換計數狀態
  };

  // 监听页面的可见性变化
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // 如果页面不可见，记录计数状态并暂停计数
        if (isCounting) {
          setIsCounting(false);
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isCounting]);



  return (
    <div>
    <div className='dizang'>

      <div className='nameAndPicture'>
        <h1 className='name'>南無地藏菩薩摩訶薩</h1>
        <img className='picture' src={`${process.env.PUBLIC_URL}/images/dizang.jpg`} alt="地藏菩薩" />
      </div>

      <div className='counter'>
        {/* 固定顯示區域 */}
        <div className='count-module'>
          <h1 className='result'>功德數: <h2 className='count-num'>{count}</h2></h1>
          <div className='dynamic-module'>
          {showText ? <img className='dynamic-picture' src={`${process.env.PUBLIC_URL}/images/dizang.jpg`} alt="地藏菩薩" /> : 
          <img style={{visibility: 'hidden'}}></img>}
            {showText ? <p className='dynamic-name'>南無地藏菩薩摩訶薩</p> : <p style={{visibility: 'hidden'}}>&nbsp;</p>}
          </div>
          

          {/* 在手動模式下顯示按鈕 */}
          {mode === 'manual' && (
            <div>
              <button onClick={handleManualCount} 
              className='count-button'>
                點擊念佛
              </button>
            </div>
          )}
        </div>
        

        <div className='choose-mode'>
          <h3>選擇計數模式:</h3>
            <div>
            <button
              onClick={() => handleModeChange('manual')}
              className='mode-style'
              style={{ backgroundColor: mode === 'manual' ? '#144197' :'lightblue' }}>
              手動計數
            </button>
            <button
              onClick={() => handleModeChange('automatic')}
              className='mode-style'
              style={{ backgroundColor: mode === 'automatic' ? '#144197' :'lightblue' }}>
              自動計數
            </button>
          </div>
          
        </div>

        {mode === 'automatic' && (
          <div className='automatic-mode'>
            <label className='input-module'>
              自定義時間間隔 (秒):
              <input
                type="number"
                value={intervalTime}
                onChange={(e) => setIntervalTime(Math.max(1, e.target.value))}
                min="1"
              />
            </label>
            <div style={{ marginTop: '10px' }}>
              <button onClick={toggleCounting}>
                {isCounting ? '停止自動計數' : '開始自動計數'}
              </button>
            </div>
          </div>
        )}

        {/* 根據模式顯示不同的說明文字 */}
        <p className='instruction'>{instruction}</p>
      </div>
    </div>

    <AppLink desc="Google play地藏法門 App: " 
    link="https://play.google.com/store/apps/details?id=com.wws.dizang" />

    <DiZangIntro />

    </div>
  );
};

export default DiZangPuSa;
