import React from 'react';
import './YaoShiFoIntro.css';

const YaoShiFoIntro = () => {
  return (
    <div className="intro-container">
      <h1 className="title">藥師佛</h1>
      <section className="section">
        <h2 className="subtitle">1. 藥師佛</h2>
        <p className="text">
          藥師佛，全名「藥師琉璃光如來」，是佛教中專門掌管治療疾病、解除痛苦與消除災難的佛。他以藍色光明照耀十方，象徵著智慧與清涼，能醫治身心疾病。
        </p>
      </section>

      <section className="section">
        <h2 className="subtitle">2. 十二大願</h2>
        <ul className="list">
          <li>願成佛後，周身光明普照，無限智慧利益眾生。</li>
          <li>願眾生得身如琉璃，內外光潔無染。</li>
          <li>願眾生無盲目與痼疾，形體端正，身心安穩。</li>
          <li>願眾生皆得無盡財富，不再貧窮困苦。</li>
          <li>願眾生得聰明智慧，正見不邪，精進不懈。</li>
          <li>願眾生轉女身為男身，得解脫法，除去眾苦。</li>
          <li>願眾生脫離外道邪見，入正道行持。</li>
          <li>願眾生脫離牢獄、枷鎖與身心折磨，得到自由。</li>
          <li>願眾生獲得飲食安樂，不受饑餓之苦。</li>
          <li>願眾生獲得美妙的衣服與寶具，滿足所有需求。</li>
          <li>願眾生得脫厄難，不受外魔侵擾，無苦得安樂。</li>
          <li>願眾生得轉迷為悟，獲得智慧光明。</li>
        </ul>
      </section>

      <section className="section">
        <h2 className="subtitle">3. 東方净琉璃世界</h2>
        <p className="text">
          藥師佛的淨土被稱為「東方淨琉璃世界」，這是一個充滿純淨光明與慈悲的佛國淨土。
        </p>
      </section>

      <section className="section">
        <h2 className="subtitle">4. 《藥師琉璃光如來本願功德經》</h2>
        <p className="text">
          《藥師琉璃光如來本願功德經》是闡述藥師佛發願的經典之一，這部經文詳細介紹了藥師佛的十二大願及其功德。
        </p>
      </section>

      <section className="section">
        <h2 className="subtitle">5. 念藥師佛計數</h2>
        <p className="text">
          修行者念誦藥師佛聖號是重要的功德行為，計數念誦的方式可以幫助修行者在持誦時保持專注，累積更多的功德。
        </p>
      </section>
    </div>
  );
};

export default YaoShiFoIntro;
