export const selfDevelopment7 = {
    id: 7,
    title: "个人成长第七弹：学会展示自己，演讲、写作、短视频",
    content: 
    <div>
      <h2>1.走博主路线</h2>
      <p>现在是注意力经济的时代，拥有写作、拍摄视频能力的人，很容易就能养活自己。做博主如果能做得好，年入百万都很轻松。尤其是拍摄视频，现在的人都爱看短视频，国内的抖音、快手，国外的Youtube、Tiktok，都养活了一大批人。所以拥有拍摄、剪辑的技能，无异于拥有了掘金的铲子，可以源源不断地带来收入。</p>
      <p>写作、拍视频的优势在于，很容易独立开展工作，天然的就具有自由职业的属性，博主们拥有更多的自由。时间自由、地点自由，所有的工作计划都由自己安排，不必受制于人。</p>

      <h2>2.面试</h2>
      <p>面试过程中，面试官只有很短的时间去了解候选者。很多时间他们并不能了解候选者的全部能力，仅仅是凭第一印象就做出判断了，而这个第一印象就来源于你在面试过程中展示出来的信息。</p>
      <p>由于面试官没有充分的时间去了解你，你必须学会展示自己，把自己最好的一面展示出来，让别人看到。这个时候学习演讲就能派上用场了，你可以在很短的时间内，自信地把自己最好的一面展示给面试官，给对方留下好印象。</p>

      <h2>3.企业内发展</h2>
      <p>世界是由草台班子组成的，有些时候并不是能者居上，只会闷头干活的人反而很难得到升迁。还是那句话，你需要让别人看到你的长处，看到你的成绩。在撰写报告、展示成果时，如果能有良好的文笔，精美的视频，那无异于如虎添翼，更容易给别人留下好印象。</p>
      <p>狼多肉少，有能力的人那么多，而那个擅长展示自己的人，更容易胜出。</p>
      
    </div>
  }

  

  








  
 
  
  
  
  
 
  



