import React from 'react';
import './GuanyinIntro.css'; // 导入 CSS 文件

function GuanyinIntro() {
    return (
        <div className="guanyin-container">
            <h1 className="guanyin-title">观音菩萨简介</h1>
            
            <section className="guanyin-section">
                <h2>1. 观音菩萨</h2>
                <p className="guanyin-text">
                    观音菩萨，全称“观世音菩萨”，意为“观听世间一切苦难众生的声音”，是佛教中最受尊崇的菩萨之一。祂以慈悲为本愿，誓愿救度一切苦难众生，特别在众生呼唤时，必定应声救护，因此被称为“慈悲的化身”。
                </p>
            </section>

            <section className="guanyin-section">
                <h2>2. 观音法门</h2>
                <ul className="guanyin-list">
                    <li>持诵观音菩萨的名号：常念“南无观世音菩萨”</li>
                    <li>《心经》：破除无明，得到解脱</li>
                    <li>《大悲咒》：消除业障，增长福慧</li>
                    <li>《大明咒》：驱除黑暗，守护清净</li>
                    <li>《普门品》：菩萨无处不现，救度众生</li>
                </ul>
            </section>

            <section className="guanyin-section">
                <h2>3. 念观音菩萨的功德</h2>
                <p className="guanyin-text">
                    念观音菩萨的名号或持诵经典与咒语，可以消灾解厄、增长智慧、消除业障，并护佑平安。通过虔诚的持念，众生能感得菩萨的慈悲护佑，遇难呈祥。
                </p>
            </section>

            <section className="guanyin-section">
                <h2>4. 念佛计数</h2>
                <p className="guanyin-text">
                    使用念佛计数器记录每日持诵的次数，可以帮助修行者保持精进，逐步提升修行功力，最终达到身心清净，获得菩萨护佑。
                </p>
            </section>
        </div>
    );
}

export default GuanyinIntro;
