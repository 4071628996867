export const selfDevelopment8 = {
    id: 8,
    title: "个人成长第八弹：创造舒适区，形成良好习惯",
    content: 
    <div>
        <h2>1.舒适区很重要</h2>
        <p>别听别人忽悠你，而盲目踏出舒适区，一点一滴的积累的成果，远比盲目从零开始靠谱得多。比如传统行业的技术从业者，看重程序员的高薪，盲目跳入IT行业，结果发现这是个吃青春饭的行业，随着行业饱和以及经济变差，很难找到一份工作，这个时候才后悔莫及。</p>
        <p>在舒适区内深耕，立稳脚跟之后再想办法扩大外沿，这样靠谱得多。比如传统行业+互联网，就是一个不错的切入点。</p>

        <h2>2.良好的习惯具有惯性</h2>
        <p>是人就会有惰性，当开始做一件事情的时候，需要花费大量的心理能量去踏出最开始的那一步，跟第一步走顺了，后面也会变得越来越顺手。</p>
        <p>当你习惯了一件事，花费的心理能量就会少很多，很自然而然就开始做了。</p>
        <p>比如你习惯了被英语单词，每日打卡，每天都会很自然地打开APP背单词，不会有什么挣扎。反之如果没有形成习惯，每次都要纠结很久，要花很长的时间才说服自己去开始做这件事情。</p>

        <h2>3.同样的地点、时间，做同样的事情，很重要</h2>
        <p>如果你经历过在旅途中办公，你就会明白，舒适区、惯性的重要性。住在新的地方，明显感觉很难开始做需要做的事情，需要花时间去适应，需要设定节奏，花很大力气去重新开始。</p>
        <p>有些时候我们并不能总能呆在同一个地方，总有出门的时候，而平常习惯做的事情往往就荒废了。不过也不是没有办法，即使不在同一个地方，在同样的时间，我们也应该做同样的事情，这样子就能形成新的节奏。</p>
        <p>比如你习惯一两天跑一次步，出门在外可能就不跑了，但这样会有很不好的影响，你的跑步状态会变差，你应该延续跑步的习惯，在同样的时间出门跑步，这样才不至于断掉。</p>
        <p>这就相当于重新创造舒适区，延续之前的习惯，同样的道理也可以应用在学习、工作上。</p>
     
    </div>
  }

  








  
 
  
  
  
  
 
  



