import React from 'react';

const NotFound = () => {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>404 - Not Found</h1>
      <h>The page you visit doesn't exist.</h>
    </div>
  );
};

export default NotFound;
