import React from 'react';
import './ZhunTiIntro.css';

const ZhunTiIntro = () => {
  return (
    <div className="zhunti-container">
      <section className="zhunti-section">
        <h1 className="zhunti-title">準提菩薩</h1>
        <p className="zhunti-text">
          準提菩薩，又稱準提佛母，是大乘佛教中重要的菩薩之一。她象徵著無量的慈悲和智慧，專門救度眾生於困境之中。準提菩薩的形象通常是身著華麗衣裳，手持法器，顯示她的威能與保護力。在許多經典中，她被描述為能聽聞眾生的苦難，並以慈悲之心施予援助。信徒相信，誦念準提咒能得到她的加持，獲得智慧和解脫。
        </p>
      </section>

      <section className="zhunti-section">
        <h1 className="zhunti-title">準提神咒</h1>
        <p className="zhunti-text">
          準提神咒是專門用來稱讚和呼喚準提菩薩的咒語，其完整的咒文包含多個音節，具有強大的力量。此咒語不僅能保護持咒者免受邪惡的侵害，還能幫助修行者消除障礙，獲得智慧和良好的運勢。準提神咒的持誦，常伴隨著對準提菩薩的虔誠祈求，讓修行者的心靈得到提升和淨化。
        </p>
      </section>

      <section className="zhunti-section">
        <h1 className="zhunti-title">準提神咒感應強烈</h1>
        <p className="zhunti-text">
          許多修行者和信徒報告，在持誦準提咒後經歷了顯著的感應和變化。這些感應包括心靈的寧靜、生活中的問題得到解決，甚至一些難以處理的困境因持咒而得到轉機。這些體驗常被視為準提菩薩的加持，顯示出此咒語的強大效力。信徒在持咒過程中感受到的內心平靜和安心，進一步鼓勵他們持續修行。
        </p>
      </section>

      <section className="zhunti-section">
        <h1 className="zhunti-title">《七俱胝佛母所說準提陀羅尼經》</h1>
        <p className="zhunti-text">
          《準提陀羅尼經》是由七俱胝佛母所翻譯的重要經典，該經深入探討了準提咒的功德與實踐。經中提到，持誦此咒能得到無量的福德、智慧和保護，並且能消除一切的罪障和苦難。準提菩薩的慈悲和智慧在經文中得到了具體的展現，信徒通過誦念經文，可以獲得與她的直接聯繫，進一步加深對於佛法的理解與實踐。
        </p>
      </section>

      <section className="zhunti-section">
        <h1 className="zhunti-title">念滿一百萬遍準提咒的功德</h1>
        <p className="zhunti-text">
          根據《準提陀羅尼經》，持咒者若能誦念準提咒滿一百萬遍，將會消除無數劫以來所造的罪障。這是因為持咒不僅能增強個人的福報與智慧，還能凈化心靈，幫助修行者達到心靈的圓滿與解脫。經文強調，這種持誦的力量源自於準提菩薩無盡的慈悲願力，持咒者在持誦的過程中，逐漸建立與菩薩的深厚聯繫，最終實現心靈的自由與安寧。
        </p>
      </section>
    </div>
  );
};

export default ZhunTiIntro;
